import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
// import { Link } from "gatsby"

import { toggleField } from '../reducers/captive'

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro/single'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import Existing from '../components/apply/existing'

import SingleBg from '../images/header-single-bg.svg'
import Family from '../images/family-happy.png'

const AltPage = ({ location, toggleField, captive }) => {
  useEffect(() => {
    toggleField('altHeaderLayout', true)
  }, [captive.altHeaderLayout])

  return (
    <Layout phone="855-504-2764" location={location}>
      <Helmet>
        <script>{`window.initiateJquery();`}</script>
      </Helmet>
      <SEO
        title="Health Insurance Quotes - Health Insurance Companion"
        keywords={[`health`, `insurance`, `plans`, `affordable`]}
      />
      <Header phone="855-504-2764" ctaAlt className="large" />
      <Intro
        showTitle={true}
        bgImage={SingleBg}
        colType="single"
        classNames="single"
        contentImage={Family}
      >
        <div className="form">
          <Existing landing={true} title="Are you currently insured?" />
        </div>
      </Intro>
      <main id="main">
        <Partners />
        <HomeContent />
      </main>
    </Layout>
  )
}

export default connect((state) => state, { toggleField })(AltPage)
